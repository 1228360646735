import React from "react";
import { Container, Row } from "react-bootstrap";
import PageWrapper from "./../components/PageWrapper";
import { Section } from "./../components/Core";
import Seo from "./../components/Seo/Seo";
import CheckAvailability from "./../sections/index/CheckAvailability";
import { findUtmParams } from "./../utils/helperFn";

const CheckAvailabilityPage = (props) => {

    if (findUtmParams(props.location.search)) {
        if (typeof window !== 'undefined' && window) {
            localStorage.setItem('utmData', JSON.stringify(findUtmParams(props.location.search)));
        }
    }

    return (
        <>
            <Seo page="check-availability"/>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-3" />
                    <Container>
                        <Row className="justify-center text-center">
                            <CheckAvailability isFocused={true}/>  
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default CheckAvailabilityPage;
